<template>
  <div class="weibo-sum-container">
    <upload-json class="upload-json-container" @success="onSuccess" />
    <export-form :export-data="tableData" :file-name="filename" />
    <month-data-table :data="tableData"></month-data-table>
  </div>
</template>
<script>
import UploadJson from '@/components/UploadJson'
import ExportForm from '../components/ExportForm'
import MonthDataTable from '../components/MonthDataTable'

import { parseTime } from '@/utils'

export default {
  name: 'Weibo',
  components: {
    UploadJson,
    MonthDataTable,
    ExportForm
  },
  data() {
    return {
      monthData: [],
      filename: ''
    }
  },
  computed: {
    tableData() {
      return this.monthData.map(item => {
        return {
          created_at: parseTime(item.created_at, '{y}年{m}月{d}日'),
          content: item.text_raw,
          url: 'https://weibo.com/' + (item.user && item.user.idstr || '') + '/' + item.mblogid,
          reads_count: item.reads_count,
          comments_count: item.comments_count,
          reposts_count: item.reposts_count,
          attitudes_count: item.attitudes_count
        }
      })
    }
  },
  methods: {
    onSuccess(fileData) {
      this.monthData = fileData.jsonData
      this.filename = fileData.name.split('.')[0]
    }
  }
}
</script>
<style lang="scss" scoped>
.upload-json-container{
    margin-bottom: 20px;
}
</style>
